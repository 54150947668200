import { ImportDialogUsedFrom } from 'interfaces';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationFile, IntegrationService } from 'interfaces/integrations';
import { useImportStore } from 'modules/library/stores/importStore';

import { WebAppImportFlow } from '../constants';
import { useFileAction } from '../hooks/useFileAction';
import { FileIcon } from './FileIcon';
import { ImportButton } from './ImportButton';

interface FileCardProps {
  service: IntegrationService;
  item: IntegrationFile;
}

export function FileCard({ service, item }: FileCardProps) {
  const dialogUsedFrom = useImportStore(s => s.dialogUsedFrom);
  const { t } = useTranslation('common');
  const importFlow = dialogUsedFrom === ImportDialogUsedFrom.PLUS_MODAL ? WebAppImportFlow.PLUS_BUTTON_MODAL : WebAppImportFlow.LIBRARY_SELECT_FILES;
  const [importState, handleClick] = useFileAction(service, item, importFlow);

  return (
    <>
      <div
        data-tip
        data-tooltip-id={!item.importable ? 'nonimportable' : ''}
        className={twMerge(
          'p-3 h-[156px] box-border flex flex-col gap-2 rounded-lg cursor-pointer',
          'transition-colors duration-75 bg-glass-200 hover:bg-glass-hovered-200 active:bg-glass-pressed-200',
          !item.importable && 'hover:bg-glass-200 active:bg-glass-200 cursor-default'
        )}
        onClick={handleClick}
      >
        <div className="flex justify-between w-full gap-1.5 shrink-0">
          <div className="text-glass-700 font-medium text-sm whitespace-nowrap overflow-hidden text-ellipsis">{item.name}</div>
          <div className="flex items-center shrink-0">
            <ImportButton
              importable={item.importable}
              isImporting={importState === 'importing'}
              isImported={importState === 'imported'}
              onClick={handleClick}
            />
          </div>
        </div>
        <div className={twMerge('bg-glass-0 rounded-sm h-full overflow-hidden', !item.imageUrl && 'flex items-center justify-center')}>
          {item.imageUrl ? <img src={item.imageUrl} className="w-full" /> : <FileIcon mimeType={item.mimeType} className="h-12 w-14" />}
        </div>
      </div>
      <ReactTooltip id="nonimportable" offset={6} variant="dark">
        {t('This file is unsupported')}
      </ReactTooltip>
    </>
  );
}
