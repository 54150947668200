import { ImportDialogUsedFrom } from 'interfaces';
import { useRouter } from 'next/router';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationFile, IntegrationService } from 'interfaces/integrations';
import { useImportStore } from 'modules/library/stores/importStore';
import { fromNow } from 'utils/dates';

import { WebAppImportFlow } from '../constants';
import { useFileAction } from '../hooks/useFileAction';
import { FileIcon } from './FileIcon';
import { ImportButton } from './ImportButton';

interface FileRowProps {
  service: IntegrationService;
  item: IntegrationFile;
  className?: string;
}

export function FileRow({ service, item, className }: FileRowProps) {
  const router = useRouter();
  const dialogUsedFrom = useImportStore(s => s.dialogUsedFrom);
  const { t } = useTranslation('common');
  const importFlow = dialogUsedFrom === ImportDialogUsedFrom.PLUS_MODAL ? WebAppImportFlow.PLUS_BUTTON_MODAL : WebAppImportFlow.LIBRARY_SELECT_FILES;
  const [importState, handleClick] = useFileAction(service, item, importFlow);

  const disabled = !item.importable;

  return (
    <>
      <div
        data-tip
        data-tooltip-id={disabled ? 'nonimportable' : ''}
        className={twMerge(
          className,
          'cursor-pointer transition-colors duration-75 hover:bg-glass-hovered-0 active:bg-glass-pressed-0',
          disabled && 'hover:bg-white active:bg-white cursor-default'
        )}
        onClick={handleClick}
      >
        <div className={twMerge('font-medium text-sm text-glass-700 px-2 py-3', disabled && 'text-glass-400')}>
          <div className="flex gap-2">
            <FileIcon mimeType={item.mimeType} className="-mt-px shrink-0 w-5 h-5" />
            <span className="whitespace-nowrap overflow-hidden text-ellipsis">{item.name}</span>
          </div>
        </div>
        <div className={twMerge('text-sm text-glass-500 px-2 py-3', disabled && 'text-glass-400')}>
          {fromNow(new Date(item.createdTime), router.locale ?? '')}
        </div>
        <div className="px-2 py-3 text-center">
          <ImportButton isImporting={importState === 'importing'} isImported={importState === 'imported'} importable={item.importable} onClick={handleClick} />
        </div>
      </div>
      <ReactTooltip id="nonimportable" offset={6} variant="dark">
        {t('This file is unsupported')}
      </ReactTooltip>
    </>
  );
}
