import { User, signInWithCustomToken, signOut } from 'firebase/auth';
import type { IUser } from 'interfaces';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { CgSpinner } from 'react-icons/cg';

import { firebaseAuth } from 'lib/firebase/firebase.client';
import { useAuthStore } from 'modules/auth/store/authStore';
import { authStoreActions } from 'modules/auth/store/authStore/actions';
import { isUserAnonymous } from 'modules/auth/store/authStore/utils/isUserAnonymous';
import { getIdToken, getUser, handleAuth } from 'utils/extension';

const USER_CREATION_REDIRECT_DATE_THRESHOLD = new Date('01-11-2023');
const isBetaApp = () => new URL(window.location.href).hostname === 'beta-app.speechify.com';

// ESLint: 'shouldPerformRedirect' is assigned a value but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const shouldPerformRedirect = (user: IUser) => {
  // if no creation time we can default to app.speechify.com
  // but its not really clear why creationTime will be nullish
  return (
    // if user created after threshold date and they are not on beta app, redirect them
    (new Date(user?.metadata?.creationTime ?? 0) >= USER_CREATION_REDIRECT_DATE_THRESHOLD && !isBetaApp()) ||
    // if user created before threshold date and they are on beta app, redirect them
    (new Date(user?.metadata?.creationTime ?? 0) < USER_CREATION_REDIRECT_DATE_THRESHOLD && isBetaApp())
  );
};

// ESLint: 'redirectToListeningExperience' is assigned a value but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const redirectToListeningExperience = () => {
  const url = new URL(window.location.href);
  url.hostname = isBetaApp() ? 'app.speechify.com' : 'beta-app.speechify.com';
  window.location.href = url.href;
};

const withAuth = (Page: $TSFixMe) => {
  const Auth = (props: $TSFixMe) => {
    const router = useRouter();
    const user = useAuthStore(state => state.user);

    const onFailure = useCallback(() => {
      window.location.href = `/login?returnTo=${encodeURIComponent(window.location.href)}`;
    }, []);

    const onSuccess = useCallback(() => {}, []);

    if (router.query.ctna) {
      // ESLint: React Hook "useCustomTokenAuth" is called conditionally
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCustomTokenAuth(router.query.ctna as string, onSuccess, onFailure);
    } else {
      // ESLint: React Hook "useSessionAuth" is called conditionally
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSessionAuth(onSuccess, onFailure);
    }

    return user ? (
      <Page {...props} />
    ) : (
      <div className="flex min-h-screen items-center justify-center bg-gray-100">
        <CgSpinner size={30} className="animate-spin" />
      </div>
    );
  };

  return Auth;
};

export default withAuth;

export const useSessionAuth = (onSuccess: (user: User, isAnonymous: boolean) => void, onFailure: (error: unknown) => void) => {
  useEffect(() => {
    authWithIdToken()
      .then(async () => {
        const token = await getCustomToken();
        const result = await signInWithCustomToken(firebaseAuth, token);
        const user = result.user;

        if (!user) {
          throw new Error('No users was returned');
        }

        authStoreActions.setUser(user);
        // update extension
        user.getIdToken().then(token => handleAuth('idtoken', token));
        onSuccess(user, isUserAnonymous(user));
      })
      .catch(e => {
        onFailure(e);
      });
  }, [onFailure, onSuccess]);
};

// ESLint: Don't use `Function` as a type & Don't use `Function` as a type
// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/ban-types
export const useCustomTokenAuth = (customToken: string, onSuccess: Function, onFailure: Function) => {
  useEffect(() => {
    signInWithCustomToken(firebaseAuth, customToken)
      .then(async result => {
        const user = result.user;

        if (user) {
          authStoreActions.setUser(user);

          const userIdToken = await user.getIdToken();
          // update extension.
          await handleAuth('idtoken', userIdToken);

          // set the sign in cookie.
          // ESLint: 'getSignedInCookie' is assigned a value but never used
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const getSignedInCookie = await fetch('/api/auth/sign-in', {
            method: 'GET',
            cache: 'no-store',
            headers: { Authorization: `Bearer ${userIdToken}` }
          });

          onSuccess(user);
        }
      })
      .catch(e => onFailure(e));
    // ESLint: React Hook useEffect has missing dependencies: 'customToken', 'dispatch', 'onFailure', and 'onSuccess'. Either include them or remove the dependency array. If 'onSuccess' changes too often, find the parent component that defines it and wrap that definition in useCallback.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const getCustomToken = async () => (await (await fetch('/api/auth/authenticate', { method: 'GET', cache: 'no-cache' })).json())?.token;

const authWithIdToken = async () => {
  const verifiedCookie = await fetch('/api/auth/check-status', { method: 'GET' })
    .then(response => response.json())
    .catch(e => console.info(e));

  // fallback to extension to acquire token
  if (!verifiedCookie) {
    const user = await getUser();

    if (user && !isUserAnonymous(user)) {
      const token = await getIdToken();

      // set the sign in cookie
      // ESLint: 'getSignedInCookie' is assigned a value but never used
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const getSignedInCookie = await fetch('/api/auth/sign-in', {
        method: 'GET',
        cache: 'no-store',
        headers: { Authorization: `Bearer ${token}` }
      });
      const result = await (await fetch('/api/auth/authenticate', { method: 'GET', cache: 'no-cache' })).json();

      if (result?.token) {
        const user = await signOut(firebaseAuth)
          .then(() => signInWithCustomToken(firebaseAuth, result.token))
          .catch(e => console.info(e));

        return user;
      }
    } else {
      throw new Error('No user authenticated');
    }
  } else
    return {
      displayName: verifiedCookie.name,
      uid: verifiedCookie.uid,
      isAnonymous: false,
      email: verifiedCookie.email,
      email_verified: verifiedCookie.email_verified
    };
};
