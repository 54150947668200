import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { CheckIcon, DocumentTextIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

import DocsIcon from 'assets/icons/docs';
import EPubIcon from 'assets/icons/epub';
import PdfIcon from 'assets/icons/pdf';
import TxtIcon from 'assets/icons/txt';
import TxtFileIcon from 'assets/icons/txtFile';
import WebIcon from 'assets/icons/web';
import { useTranslation } from 'hooks/useTypedTranslation';
import { ALLOWED_MIME_TYPES } from 'interfaces/import';
import { useIntegrationStore } from 'modules/integration/stores/integrationStore';
import { integrationStoreActions } from 'modules/integration/stores/integrationStore/actions';

const filters = {
  [ALLOWED_MIME_TYPES.PDF]: { title: 'PDFs', icon: PdfIcon },
  [`${ALLOWED_MIME_TYPES.DOC}|${ALLOWED_MIME_TYPES.DOCX}`]: { title: 'Word', icon: DocsIcon },
  [ALLOWED_MIME_TYPES.GDOC]: { title: 'Google docs', icon: DocsIcon },
  [ALLOWED_MIME_TYPES.HTML]: { title: 'Web Link', icon: WebIcon },
  [ALLOWED_MIME_TYPES.TXT]: { title: 'Text documents', icon: TxtIcon },
  [ALLOWED_MIME_TYPES.EPUB]: { title: 'ePubs', icon: EPubIcon }
} as const;

export function FileTypeFilter() {
  const { t } = useTranslation('common');
  const filter = useIntegrationStore(state => ((state.filter ?? '' in filters) ? (state.filter as keyof typeof filters) : undefined));

  return (
    <div className="flex text-sm text-glass-500 gap-2">
      <Menu as="div" className="relative text-left">
        {({ open }) => (
          <>
            <Menu.Button
              className={twMerge(
                'flex gap-1.5 items-center py-2 px-3 rounded-lg',
                'transition-colors text-glass-500',
                !open && 'hover:bg-glass-hovered-0 active:bg-glass-pressed-0',
                open && 'bg-glass-pressed-0'
              )}
            >
              <span>{filter ? `${filters[filter].title}` : 'All files'}</span>
              <ChevronDownIcon className="h-4 w-4 text-glass-500" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-1 min-w-44 max-w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none overflow-hidden">
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={twMerge(
                          active && 'bg-glass-hovered-0',
                          'text-glass-700 group flex w-full gap-2 items-center px-3 py-2.5 text-sm whitespace-nowrap transition-colors'
                        )}
                        onClick={() => integrationStoreActions.setFilter(undefined)}
                      >
                        {!filter ? <CheckIcon className="-mt-px h-5 w-5 text-glass-500" /> : <DocumentTextIcon className="-mt-px h-5 w-5 text-glass-500" />}
                        <span className="whitespace-nowrap">{t('All files')}</span>
                      </button>
                    )}
                  </Menu.Item>
                  {Object.entries(filters).map(([mimeType, { title, icon }]) => (
                    <Menu.Item key={mimeType}>
                      {({ active }) => (
                        <button
                          className={twMerge(
                            active && 'bg-glass-hovered-0',
                            'text-glass-700 group flex w-full gap-2 items-center px-3 py-2.5 text-sm whitespace-nowrap transition-colors'
                          )}
                          onClick={() => integrationStoreActions.setFilter(mimeType)}
                        >
                          {mimeType === filter ? (
                            <CheckIcon className="-mt-px h-5 w-5 text-glass-500" />
                          ) : (
                            React.createElement(icon ?? TxtFileIcon, { className: '-mt-[2px] h-5 w-5 text-glass-500 flex-shrink-0' })
                          )}
                          <span className="whitespace-nowrap">{t(title)}</span>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
