import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ComponentType, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'store';

import { loadFeatureFlags } from 'hooks/useFeatureFlags';
import * as speechify from 'lib/speechify';
import { setAudioServerUser } from 'lib/speechify/adaptors/audioServer';
import { AnalyticsEventKey } from 'modules/analytics/analyticsTypes';
import { currentUserEmitter } from 'modules/auth/store/authUserEmitter';
import { logAuthAnalyticsEvent } from 'modules/auth/utils/analytic';
import { usageStoreActions } from 'modules/usage/store/actions';
import { actions as appActions } from 'store/app';
import { gamificationMiddlewareOnSignedOut } from 'store/gamification/middleware';
import { gamificationMiddlewareOnSignedIn } from 'store/gamification/middleware';
import { libraryMiddlewareOnUserLogout } from 'store/library/middleware';

const CheckoutModal = dynamic(() => import('modules/checkout/components/CheckoutModal'), {});

export default function withLegacyBaseApp<T extends object>(ReactComponent: ComponentType<T>): ComponentType<T> {
  const WithLegacyBaseAppComponent = (props: T) => {
    const router = useRouter();

    useEffect(() => {
      store.dispatch(appActions.setLocale(router.locale));

      speechify.auth.onAuthStateChanged(async user => {
        if (user?.uid) {
          logAuthAnalyticsEvent(AnalyticsEventKey.userSignedIn);
          loadFeatureFlags({ uid: user.uid, email: user.email ?? undefined });
          usageStoreActions.fetchUserUsageData();
          setAudioServerUser(user);
        }
      });

      const cleanupLogoutListener = currentUserEmitter.addEventListener('onUserLogout', () => {
        // TODO(library-overhaul): delete these once library overhaul is complete
        // previously these were defined in the libraryMiddleware on Redux
        libraryMiddlewareOnUserLogout(store.dispatch);
        gamificationMiddlewareOnSignedOut(store.dispatch);
      });

      const cleanupUserUpdateListener = currentUserEmitter.addEventListener('onUserUpdate', () => {
        gamificationMiddlewareOnSignedIn(store.dispatch);
      });

      return () => {
        cleanupLogoutListener();
        cleanupUserUpdateListener();
      };

      // ESLint: React Hook useEffect has a missing dependency: 'router.locale'. Either include it or remove the dependency array.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CheckoutModal />
          <ReactComponent {...props} />
        </PersistGate>
      </Provider>
    );
  };
  return WithLegacyBaseAppComponent;
}
