import React from 'react';
import { twMerge } from 'tailwind-merge';

import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationService } from 'interfaces/integrations';
import { useIntegrationStore } from 'modules/integration/stores/integrationStore';
import { integrationStoreSelectors } from 'modules/integration/stores/integrationStore/selectors';

import { IntegrationIcons, IntegrationTitles } from '../constants';

interface IntegrationCardProps {
  service: IntegrationService;
  onClick?: () => void;
  className?: string;
}

export function IntegrationCard({ service, className, children, onClick }: React.PropsWithChildren<IntegrationCardProps>): JSX.Element {
  const { t } = useTranslation('common');
  const authorizeData = useIntegrationStore(integrationStoreSelectors.getAuthByService(service));

  const ServiceIcon = IntegrationIcons[service];
  const { name, message } = IntegrationTitles[service];

  const isAuthorized = authorizeData.authorized;

  return (
    <>
      <div className={twMerge('flex flex-col items-center', className)} onClick={onClick}>
        <ServiceIcon className="w-[50px] h-[50px]" />
        <div className="my-3 flex flex-col items-center w-full">
          <p>{isAuthorized ? name : `Connect ${name}`}</p>
          <p className="text-sm text-glass-500 break-words max-w-full text-center mt-1">{isAuthorized ? authorizeData.userMetadata.email : t(message)}</p>
        </div>
        {children}
      </div>
    </>
  );
}
