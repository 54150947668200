import { ChevronDownIcon, ChevronUpIcon, DocumentIcon } from '@heroicons/react/outline';
import type { SpeechifyURI } from '@speechifyinc/multiplatform-sdk';
import React, { useCallback, useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import { CgSpinner } from 'react-icons/cg';

import { CloseIcon } from 'assets/icons/close-2';

import PdfFileIcon from '../../assets/icons/pdfFile';
import TxtFileIcon from '../../assets/icons/txtFile';
import { logSegmentEvent } from '../../utils/analytics';
import Loading from '../elements/Loading';

export enum UploadStatusEnum {
  Ready = 'Ready',
  Processing = 'Processing...',
  Complete = 'Upload Complete!',
  Cancelled = 'Cancelled',
  Failed = 'Failed'
}

export interface UploadingFile {
  id: string;
  file: File;
  uri?: SpeechifyURI;
  folderId?: string;
  status: UploadStatusEnum;
}

interface FileRowProps {
  uploadingFile: UploadingFile;
  onOpen?: (file: UploadingFile) => void;
  onCancel?: () => void;
  isAvailable?: boolean;
}

const getFileIcon = (fileType: $TSFixMe) => (
  <div className="flex justify-center self-center text-glass-500">
    {fileType === 'application/pdf' ? <PdfFileIcon /> : fileType === 'text/plain' ? <TxtFileIcon /> : <DocumentIcon className="flex h-8 w-8 self-center" />}
  </div>
);

const FileRow: React.FC<FileRowProps> = ({ uploadingFile, onOpen, isAvailable }) => {
  const { file, status, uri } = uploadingFile;

  const onClickItem = useCallback(() => {
    if (uri) {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onOpen(uploadingFile);
    }
    // ESLint: React Hook useCallback has a missing dependency: 'onOpen'. Either include it or remove the dependency array. If 'onOpen' changes too often, find the parent component that defines it and wrap that definition in useCallback.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri, uploadingFile]);

  return (
    <div className="grid grid-cols-[32px_1fr_fit-content(85px)] gap-2 border-b-[1px] border-glass-200 bg-glass-0 p-4">
      {getFileIcon(file.type)}
      <div className="flex flex-col truncate">
        <div className="truncate text-sm">{file.name}</div>
        <div className="truncate text-xs font-normal text-glass-400">{status}</div>
      </div>
      <div className="flex cursor-pointer content-between items-center gap-3">
        {uri && isAvailable && (
          <span className="text-sm font-normal text-electric-400" onClick={onClickItem}>
            Open
          </span>
        )}
        {status === UploadStatusEnum.Ready && <CgSpinner size={32} />}
        {status === UploadStatusEnum.Processing && (
          <div className="flex items-center justify-center">
            <Loading size={32} />
          </div>
        )}
        {status === UploadStatusEnum.Failed && <BiError size={32} />}
        {status === UploadStatusEnum.Complete && <AiFillCheckCircle size={32} color="#0FA51E" onClick={onClickItem} />}
      </div>
    </div>
  );
};
interface FileUploaderProps {
  uploadingFiles: Array<UploadingFile>;
  onOpen?: (file: UploadingFile) => void;
  onClose?: () => void;
}

export const FileUploader: React.FC<FileUploaderProps> = ({ uploadingFiles, onOpen, onClose }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [uploadingLabel, setUploadingLabel] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    const processingFiles = uploadingFiles.filter(f => f.status === UploadStatusEnum.Processing);
    if (processingFiles.length > 0) {
      setUploadingLabel(`Uploading ${processingFiles?.length} item${processingFiles.length > 1 ? 's' : ''}`);
    } else {
      const completeFiles = uploadingFiles.filter(f => f.status === UploadStatusEnum.Complete);
      if (completeFiles.length > 0) {
        setUploadingLabel(`${completeFiles?.length} upload${completeFiles.length > 1 ? 's' : ''} complete`);
      } else {
        setUploadingLabel('');
      }
    }

    setIsAvailable(uploadingFiles.filter(f => f.status === UploadStatusEnum.Complete).length === uploadingFiles.length);
  }, [uploadingFiles]);

  const handleOpen = (file: $TSFixMe) => {
    logSegmentEvent('web_app_batch_uploading_tracker_click_open_item');
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onOpen(file);
  };
  const handleClose = () => {
    logSegmentEvent('web_app_batch_uploading_tracker_click_close');
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onClose();
  };
  const handleCollapse = () => {
    logSegmentEvent('web_app_batch_uploading_tracker_click_collapse_expand');
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="fixed bottom-0 right-[80px] z-2000 w-card rounded-t-xl border-[1px] border-b-0 border-glass-700">
      <div className="relative flex w-card justify-between rounded-t-xl bg-glass-700 px-4 py-3 text-glass-0">
        <div className="font-ABCDiatype text-sm font-bold">{uploadingLabel}</div>
        <div className="grid grid-cols-2 gap-4">
          {isCollapsed ? (
            <ChevronUpIcon className="h-5 w-5 cursor-pointer" onClick={handleCollapse} />
          ) : (
            <ChevronDownIcon className="h-5 w-5 cursor-pointer" onClick={handleCollapse} />
          )}
          <CloseIcon className="h-5 w-5 cursor-pointer fill-glass-0" onClick={handleClose} />
        </div>
      </div>

      {!isCollapsed && (
        <div className="max-h-80 overflow-y-auto">
          {uploadingFiles.map(f => (
            <FileRow key={f.id} uploadingFile={f} onOpen={handleOpen} isAvailable={isAvailable} />
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploader;
