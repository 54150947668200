import { Menu, Transition } from '@headlessui/react';
import { ArrowDownIcon, ArrowUpIcon, CalendarIcon, ChevronDownIcon } from '@heroicons/react/outline';
import React, { Fragment, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import AlphabeticalOrderIcon from 'assets/icons/alphabetical-order';
import { useTranslation } from 'hooks/useTypedTranslation';
import { SORT_BY_FIELD, SORT_ORDER } from 'interfaces/integrations';
import { useIntegrationStore } from 'modules/integration/stores/integrationStore';
import { integrationStoreActions } from 'modules/integration/stores/integrationStore/actions';
import { integrationStoreSelectors } from 'modules/integration/stores/integrationStore/selectors';

import { getInverseSortOrder } from '../../utils';

enum ORDER_DIRECTION {
  UP = 'up',
  DOWN = 'down'
}

const SortingConfig = {
  [SORT_BY_FIELD.NAME]: {
    title: 'Name',
    default: SORT_ORDER.ASC,
    icon: AlphabeticalOrderIcon,
    orders: {
      [SORT_ORDER.ASC]: { title: 'A-Z', direction: ORDER_DIRECTION.DOWN },
      [SORT_ORDER.DESC]: { title: 'Z-A', direction: ORDER_DIRECTION.UP }
    }
  },
  [SORT_BY_FIELD.CREATED_TIME]: {
    title: 'Date Added',
    default: SORT_ORDER.DESC,
    icon: CalendarIcon,
    orders: {
      [SORT_ORDER.ASC]: { title: 'Oldest first', direction: ORDER_DIRECTION.UP },
      [SORT_ORDER.DESC]: { title: 'Newest first', direction: ORDER_DIRECTION.DOWN }
    }
  }
} as const;

export function SortFilter() {
  const { t } = useTranslation('common');

  const sortOrder = useIntegrationStore(integrationStoreSelectors.getSortOrder);
  const sortBy = useIntegrationStore(integrationStoreSelectors.getSortBy);
  const sortConfig = SortingConfig[sortBy];

  const setSortBy = useCallback(
    (nextSortBy: SORT_BY_FIELD) => {
      integrationStoreActions.setOrder({
        sortBy: nextSortBy,
        sortOrder: sortBy === nextSortBy ? getInverseSortOrder(sortOrder) : SortingConfig[nextSortBy].default
      });
    },
    [sortOrder, sortBy]
  );

  return (
    <div className="flex text-sm text-glass-500 gap-2">
      <Menu as="div" className="relative text-left">
        {({ open }) => (
          <>
            <Menu.Button
              className={twMerge(
                'flex gap-1.5 items-center py-2 px-3 rounded-lg',
                'transition-colors text-glass-500',
                !open && 'hover:bg-glass-hovered-0 active:bg-glass-pressed-0',
                open && 'bg-glass-pressed-0'
              )}
            >
              <span>{sortConfig.title}</span>
              <ChevronDownIcon className="h-4 w-4 text-glass-500" aria-hidden="true" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-1 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none overflow-hidden">
                <div>
                  {[SORT_BY_FIELD.CREATED_TIME, SORT_BY_FIELD.NAME].map(sortByField => (
                    <Menu.Item key={sortByField}>
                      {({ active }) => (
                        <button
                          className={twMerge(
                            active && 'bg-glass-hovered-0',
                            'text-glass-700 group flex w-full gap-2 items-center px-3 py-2.5 text-sm whitespace-nowrap transition-colors'
                          )}
                          onClick={() => setSortBy(sortByField)}
                        >
                          {sortBy === sortByField ? (
                            SortingConfig[sortByField].orders[sortOrder].direction === ORDER_DIRECTION.DOWN ? (
                              <ArrowDownIcon className="-mt-px h-5 w-5 p-0.5 text-glass-500" />
                            ) : (
                              <ArrowUpIcon className="-mt-px h-5 w-5 p-0.5 text-glass-500" />
                            )
                          ) : (
                            <div className="h-5 w-5"></div>
                          )}
                          {React.createElement(SortingConfig[sortByField].icon, { className: '-mt-px h-4 w-4 text-glass-500' })}
                          <span>{t(SortingConfig[sortByField].title)}</span>
                          {sortBy === sortByField && (
                            <span className="text-xs text-glass-500 ml-auto">{t(SortingConfig[sortByField].orders[sortOrder].title)}</span>
                          )}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
