import React, { useCallback } from 'react';

import { Spinner } from 'assets/images';
import { IntegrationService } from 'interfaces/integrations';
import { importStoreActions } from 'modules/library/stores/importStore/actions';

import { IntegrationIcons, IntegrationTitles } from './constants';
import { useIntegrationAuth } from './hooks/useIntegrationAuth';
import { ImportSourceCard } from './ImportSourceCard';

export function CanvasIntegrationCard() {
  const [authorizeData, isAuthorizing] = useIntegrationAuth(IntegrationService.CANVAS);

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      if (authorizeData.authorized) return;

      e.stopPropagation();

      importStoreActions.openImportDialog({ source: IntegrationService.CANVAS });
    },
    [authorizeData]
  );

  return (
    <ImportSourceCard
      icon={IntegrationIcons[IntegrationService.CANVAS]}
      title={IntegrationTitles[IntegrationService.CANVAS].name}
      buttonType={authorizeData.authorized ? 'outline' : 'solid'}
      buttonText={
        authorizeData.authorized ? 'View Files' : isAuthorizing ? <Spinner width="20" fill="white" className="animate-spin inline-block" /> : 'Connect'
      }
      onClick={handleClick}
    />
  );
}
