import { Menu, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import ModalComponent from 'components/elements/Modal';
import React, { Fragment, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Spinner } from 'assets/images';
import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationService } from 'interfaces/integrations';
import { useIntegrationStore } from 'modules/integration/stores/integrationStore';
import { integrationStoreActions } from 'modules/integration/stores/integrationStore/actions';
import { integrationStoreSelectors } from 'modules/integration/stores/integrationStore/selectors';
import { importStoreActions } from 'modules/library/stores/importStore/actions';

import { IntegrationTitles } from './constants';
import { IntegrationCard } from './elements/IntegrationCard';

interface IntegrationDialogTitleProps {
  service: IntegrationService;
}

export function IntegrationDialogTitle({ service }: IntegrationDialogTitleProps) {
  const { t } = useTranslation('common');
  const authorizeData = useIntegrationStore(integrationStoreSelectors.getAuthByService(service));
  const suggestionsEnabled = useIntegrationStore(integrationStoreSelectors.isSuggestionEnabled(service));
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);

  const handleSuggestionsToggle = useCallback(() => {
    return suggestionsEnabled ? integrationStoreActions.disableSuggestions(service) : integrationStoreActions.enableSuggestions(service);
  }, [suggestionsEnabled, service]);

  const handleLogout = useCallback(async () => {
    setIsLoggingOut(true);
    await integrationStoreActions.logoutFromService(service);
    importStoreActions.setImportSource({ source: null, dialogUsedFrom: null });
    setIsLoggingOut(false);
  }, [service]);

  return (
    <>
      <Menu as="div" className="relative text-left">
        <Menu.Button className="flex items-center gap-2 transition-opacity hover:opacity-80 active:opacity-60">
          {t('Import from')} {IntegrationTitles[service].name}
          {authorizeData.authorized && <ChevronDownIcon className="h-5 w-5 text-glass-500" aria-hidden="true" />}
        </Menu.Button>
        {authorizeData.userMetadata && (
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-50 left-full -translate-x-1/2 mt-1 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <Menu.Item>
                <div className="p-4 flex items-center justify-center">
                  <XIcon className="absolute right-1 top-1 float-right block h-5 w-5 cursor-pointer text-glass-500 sm:h-6" aria-hidden="true" />
                  <div onClick={e => e.preventDefault()} className="w-full">
                    <IntegrationCard service={service}>
                      <div className="flex flex-col w-full gap-2">
                        <button
                          className={twMerge(
                            'flex justify-center flex-initial min-w-40',
                            'py-[7px] px-1 rounded-[8px] transition-colors',
                            'text-sm text-glass-500',
                            'bg-transparent border border-glass-300 hover:border-glass-hovered-300 active:border-glass-pressed-300'
                          )}
                          onClick={handleSuggestionsToggle}
                        >
                          {suggestionsEnabled ? 'Hide Import Suggestions' : 'Show Import Suggestions'}
                        </button>
                        <button
                          className={twMerge(
                            'flex justify-center flex-initial min-w-40',
                            'py-[7px] px-1 rounded-[8px] transition-colors',
                            'text-sm text-red-400 hover:text-red-hovered-400 active:text-red-pressed-400',
                            'bg-transparent border border-red-400 hover:border-red-hovered-400 active:border-red-pressed-400'
                          )}
                          onClick={() => setConfirmLogoutOpen(true)}
                        >
                          {t('Remove integration')}
                        </button>
                      </div>
                    </IntegrationCard>
                  </div>
                </div>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        )}
      </Menu>

      <ModalComponent open={confirmLogoutOpen} onClose={() => setConfirmLogoutOpen(false)} classNames="font-ABCDiatype" dialogClassNames="!z-2000">
        <div className="p-6">
          <h3 className="mb-1 text-xl font-semibold text-glass-700">{t('Remove Integration?', { service: IntegrationTitles[service].name })}</h3>
          <p className="text-sm text-glass-500">{t('Previously imported documents will stay in Speechify.')}</p>
          <p className="text-sm text-glass-500">{t("However, you won't be able to import new ones.")}</p>
        </div>
        <div className="bg-glass-200 px-6 py-3 flex justify-end gap-3">
          <button
            className={twMerge(
              'min-w-24 flex justify-center flex-initial',
              'py-2 px-4 rounded-[8px] transition-colors',
              'text-sm text-glass-500 hover:bg-glass-hovered-0 active:bg-glass-pressed-0'
            )}
            onClick={() => setConfirmLogoutOpen(false)}
          >
            {t('Cancel')}
          </button>
          <button
            className={twMerge(
              'min-w-24 flex justify-center flex-initial',
              'py-2 px-4 rounded-[8px] transition-colors',
              'text-sm text-glass-0 bg-red-400 hover:bg-red-hovered-400 active:bg-red-pressed-400'
            )}
            onClick={handleLogout}
            disabled={isLoggingOut}
          >
            {isLoggingOut ? <Spinner width="20" fill="white" className="animate-spin" /> : t('Remove')}
          </button>
        </div>
      </ModalComponent>
    </>
  );
}
