import { IUser } from 'interfaces';

const ONE_DAY_IN_MS = 60 * 60 * 24 * 1000;

export const isNewUserWithinFirstDay = (user: IUser): boolean => {
  let newUser = false;

  if (user.metadata) {
    // @ts-expect-error TS(2769): No overload matches this call.
    const createdDate = new Date(user.metadata.creationTime);
    // @ts-expect-error TS(2769): No overload matches this call.
    const lastSignDate = new Date(user.metadata.lastSignInTime);
    const diff = lastSignDate.getTime() - createdDate.getTime();

    newUser = diff <= ONE_DAY_IN_MS;
  }

  return newUser;
};

export const isNewUserSince = (user: IUser, newUserThreshold: number = new Date('2024-08-01').getTime()): boolean => {
  const { metadata } = user;

  if (!metadata?.creationTime) {
    return false;
  }

  const creationTime = new Date(metadata.creationTime).getTime();

  if (isNaN(creationTime)) {
    return false;
  }

  return creationTime >= newUserThreshold;
};
