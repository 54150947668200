import type { AppDispatch } from 'store';

import { isAnonymous as isUserAnonymous } from 'lib/speechify';
import { useAuthStore } from 'modules/auth/store/authStore';
import { actions as gamificationActions } from 'store/gamification';
import { disableGamification, enableGamification } from 'utils/extension';

import { getGamificationFeatureFlag } from './helpers';

const turnOffGamification = (dispatch: AppDispatch) => {
  dispatch(gamificationActions.clear());
  dispatch(gamificationActions.unsubscribe());
  disableGamification();
};

export const gamificationMiddlewareOnSignedIn = (dispatch: AppDispatch) => {
  const { user, firebaseUser } = useAuthStore.getState();
  if (firebaseUser && !isUserAnonymous(firebaseUser)) {
    getGamificationFeatureFlag(user!).then(value => {
      if (value === 'enabled') {
        dispatch(gamificationActions.subscribe(user!.uid!));
        enableGamification();
      } else {
        turnOffGamification(dispatch);
      }
    });
  }
};

export const gamificationMiddlewareOnSignedOut = (dispatch: AppDispatch) => {
  turnOffGamification(dispatch);
};
