import { Popover } from '@headlessui/react';
import { MoveToModal, RenameModal } from 'components/library';
import { NavDownloadMp3PendingIcon } from 'components/newListeningExperience/topnav/icons/DownloadMp3PendingIcon';
import { ContactSupportAlertBanner } from 'components/settings/ContactSupportAlertBanner';
import { IRecord, ItemActionType } from 'interfaces';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useState } from 'react';
import { RootState, useDispatch, useSelector } from 'store';
import { twMerge } from 'tailwind-merge';
import { carryParams, isItemPage } from 'utils';

import BackIcon from 'assets/icons/back';
import ReportIcon from 'assets/icons/report';
import { Logo } from 'assets/images';
import { ErrorSource } from 'config/constants/errors';
import { useNavigate } from 'hooks/useNavigate';
import { useTranslation } from 'hooks/useTypedTranslation';
import { logError } from 'lib/observability';
import * as speechify from 'lib/speechify';
import { useAuthStore } from 'modules/auth/store/authStore';
import { authStoreActions } from 'modules/auth/store/authStore/actions';
import { addToast } from 'modules/toast/stores/actions';
import { showUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';
import { getSource, logSegmentEvent } from 'utils/analytics';

import { actions as itemActions } from '../../store/item';
import ConfirmItemActionModal from './ConfirmItemActionModal';
import ConfirmPersonalVoiceActionModal from './ConfirmPersonalVoiceActionModal';
import HeaderAvatar from './HeaderAvatar';
import ItemDownload from './ItemDownload';
import SearchBar from './SearchBar';

const StreakCounter = dynamic(() => import('../gamification/StreakCounter'), { ssr: false });

const Back = () => {
  const router = useRouter();
  const navigate = useNavigate();

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBackClick = (event: any) => {
    logSegmentEvent('web_app_home_button_pressed', { source: getSource(router.pathname) });
    event.preventDefault();
    navigate(carryParams('/'));
  };

  return (
    <a href="/" className="flex h-5 w-5 items-center justify-center" onClick={handleBackClick}>
      <BackIcon className="h-5 w-5 [&>path]:fill-glass-700 dark:[&>path]:fill-glass-0" />
    </a>
  );
};

// ESLint: 'ReportBtn' is assigned a value but never used & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
const ReportBtn = ({ onClick }: any) => {
  const { t } = useTranslation('common');

  return (
    <div className="ml-4 hidden flex-shrink-0 cursor-pointer select-none items-center text-glass-500 md:flex" onClick={() => onClick()}>
      <ReportIcon />
      <span className="ml-1 font-ABCDiatype text-sm font-medium sm:text-base">{t('Report')}</span>
    </div>
  );
};

export const Home = () => {
  const router = useRouter();
  const navigate = useNavigate();

  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    event.preventDefault();

    navigate('/');

    logSegmentEvent('web_app_logo_pressed', { source: getSource(router.pathname) });
  };

  return (
    <div className="flex flex-shrink-0 items-center">
      <a className="text-speechify-blue-600 hover:text-speechify-blue-700" href="/" onClick={handleClick}>
        <Logo aria-hidden="true" />
      </a>
    </div>
  );
};

const TitleBar = ({ title }: { title?: string }) => {
  const maxLength = 60;

  return (
    <div className="mt-2 min-w-0 flex-1 px-2 md:px-8 lg:px-0 xl:col-span-7">
      <div className="flex items-center justify-center truncate px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
        <h3 className="text-sm font-bold text-glass-700 md:text-base ">
          &nbsp;{title && title.length > maxLength ? title.substr(0, maxLength) + '...' : title}&nbsp;
        </h3>
      </div>
    </div>
  );
};

interface HeaderProps {
  avatar?: boolean;
  back?: boolean;
  fixed?: boolean;
  newlyImportedItemId?: string;
  search?: boolean;
  title?: string;
  showContactSupportBanner?: boolean;
  onClickReportBtn?: () => void;
}

export const Header: React.FC<HeaderProps> = props => {
  const { avatar = true, back, search = true, title, showContactSupportBanner } = props;
  const [hideControls, setHideControls] = useState(false);

  const { t } = useTranslation('common');
  const router = useRouter();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useAuthStore(state => state.user);
  const subscription = useAuthStore(state => state.user?.subscription);
  const { folders } = useSelector((state: RootState) => state.library);

  const [canResumeCancelledSubscription, setCanResumeCancelledSubscription] = useState(false);

  useEffect(() => {
    setHideControls(localStorage.getItem('hidecontrols') === 'true');

    window.addEventListener('preference', () => {
      setHideControls(localStorage.getItem('hidecontrols') === 'true');
    });
  }, []);

  useEffect(() => {
    const init = async () => {
      if (speechify.isStripe(currentUser) && speechify.isCanceled(currentUser)) {
        const willChargeUser = await speechify.willChargeUserForRenewal();

        if (!willChargeUser) {
          setCanResumeCancelledSubscription(true);
        }
      }
    };

    setCanResumeCancelledSubscription(false);

    if (currentUser?.uid) {
      init();
    }
    // ESLint: React Hook useEffect has a missing dependency: 'currentUser'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.uid, subscription?.status]);

  // ESLint: 'location' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleItemAction = async (item: IRecord, actionType: ItemActionType, location: string = 'library') => {
    switch (actionType) {
      case ItemActionType.Delete:
        dispatch(itemActions.setConfirmItem({ item, action: actionType }));
        break;

      case ItemActionType.Rename:
        dispatch(itemActions.setItemToRename({ item, titleOverride: title }));
        break;

      case ItemActionType.Move:
        dispatch(itemActions.setItemToMove(item));
        break;

      case ItemActionType.OpenOriginal:
        if (item.originalSourceURL) {
          window.open(item.originalSourceURL, '_blank');
        }
        break;

      case ItemActionType.Archive:
        dispatch(itemActions.setConfirmItem({ item, action: actionType }));
        break;
    }
  };

  const handleResumeClick = async () => {
    logSegmentEvent('web_app_resume_pressed', { source: getSource(router.pathname) });

    try {
      await speechify.renewCancelledSubscription();

      await speechify.auth.currentUser?.reload?.();
      authStoreActions.setUser(speechify.auth.currentUser);

      addToast({
        title: '',
        description: t('Your subscription has been renewed.')!,
        type: 'success'
      });

      setCanResumeCancelledSubscription(false);
      navigate('/settings?tab=Subscription');
    } catch (ex) {
      logError(ex as Error, ErrorSource.SUBSCRIPTION);
      logSegmentEvent('web_app_resume_pressed_error', { source: getSource(router.pathname) });
      window.open('https://checkout.speechify.com/oneclick', 'blank');
    }
  };

  const handleUpgradeClick = () => {
    showUpsellModal(UpsellModalType.Global);
  };

  const upgradeText = speechify.isExpired(currentUser) ? 'Renew' : 'Upgrade';

  return (
    <>
      {showContactSupportBanner && <ContactSupportAlertBanner />}
      <ConfirmItemActionModal />
      <ConfirmPersonalVoiceActionModal />
      <MoveToModal folders={folders} />
      <RenameModal />
      <ItemDownload />

      <Popover
        as="header"
        className={({ open }) =>
          twMerge(
            open ? 'inset-0 overflow-y-auto' : '',
            isItemPage() && hideControls ? 'opacity-0 hover:opacity-100' : 'opacity-100',
            'z-[49]',
            'w-full',
            'bg-glass-0',
            'sticky',
            'top-0',
            'lg:overflow-y-visible'
          )
        }
      >
        {() => {
          return (
            <Fragment>
              <div className="mx-auto px-5">
                <div className="relative flex h-16 items-center justify-between">
                  {back && (
                    <div className="flex items-center px-2 lg:px-0 xl:w-64">
                      <Back />
                    </div>
                  )}
                  {search && <SearchBar />}
                  {!search && <TitleBar title={title} />}

                  {avatar && (
                    <div className="relative ml-0 flex-shrink-0 lg:ml-6">
                      <div className="flex items-center gap-2">
                        {speechify.canUpgrade(currentUser) && (
                          <button
                            className="mr-2 flex h-9 w-24 items-center justify-center rounded-lg bg-yellow-400 text-sm font-medium text-glass-700"
                            onClick={handleUpgradeClick}
                          >
                            <span>{t(upgradeText)}</span>
                          </button>
                        )}

                        {canResumeCancelledSubscription && (
                          <button
                            className="mr-4 hidden h-9 w-44 items-center justify-center rounded-lg bg-yellow-400 text-sm font-medium text-glass-700 md:flex"
                            onClick={handleResumeClick}
                          >
                            <span>{t('Resume Subscription')}</span>
                          </button>
                        )}

                        <StreakCounter />

                        <NavDownloadMp3PendingIcon size={24} buttonClassName="p-spl-3 hover:bg-glass-200" placeModal="right" />

                        <HeaderAvatar isInstantListening={false} size={32} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          );
        }}
      </Popover>
    </>
  );
};

export default Header;
